import "./styles.scss"

import React from "react"

const Content = () => {
  return (
    <div className="st2-landing-content">
      <h1>
        PREMIERA <span>INWESTYCJI</span>
      </h1>
      <div className="st2-landing-content__description">
        <h2>
          Wkrótce
          <br />
          rusza sprzedaż <br /> II etapu Sky Trust.
        </h2>
        <p>Nowy symbol Kielc</p>
      </div>
    </div>
  )
}

export default Content
