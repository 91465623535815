import "./styles.scss"

import React, { useState } from "react"
import axios from "axios"
import { motion, AnimatePresence } from "framer-motion"
import { navigate } from "gatsby"

const Form = () => {
  const formID = "19200"
  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    investment: "Sky Trust - etap II",
    firstName: "",
    email: "",
    phone: "",
    pageurl: isBrowser ? window.location.href : null,
    legal: false,
  })

  const [isSending, setIsSending] = useState(false)

  const handleFormSubmit = e => {
    e.preventDefault()
    setIsSending(true)

    let form = new FormData()

    Object.entries(formData).forEach(([key, value]) => {
      form.set(key, value)
    })

    axios({
      method: "post",
      url: `https://trust-investment.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        navigate("/premiera-skytrust-etap-ii-potwierdzenie/")
      })
      .catch(() => {
        setIsSending(false)
      })
  }

  return (
    <form className="st2-landing-form" onSubmit={handleFormSubmit}>
      <Input
        id="firstName"
        label="Imię i nazwisko"
        value={formData.firstName}
        required
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            firstName: e.target.value,
          }))
        }
      />
      <Input
        id="email"
        type="email"
        label="e-mail"
        value={formData.email}
        required
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            email: e.target.value,
          }))
        }
      />
      <Input
        id="phone"
        type="tel"
        label="numer telefonu"
        value={formData.phone}
        required
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            phone: e.target.value,
          }))
        }
      />
      <Checkbox
        checked={formData.legal}
        required
        more="KRS: 0000747790 (Administrator danych), w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej polityce prywatności."
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            legal: e.target.checked,
          }))
        }
      >
        Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez
        TRUST INVESTMENT SPÓŁKA AKCYJNA, ul. Robotnicza 1, 25-662 Kielce NIP:
        6631877218, REGON: 381244959
      </Checkbox>
      <div className="st2-landing-form__submit">
        <button type="submit" disabled={!formData.legal || isSending}>
          {isSending ? "Wysyłanie..." : "Jako pierwszy"}
          <span>
            <img
              src={
                require("assets/images/sky-trust-ii-landing/arrow-right.svg")
                  .default
              }
              alt=""
            />
          </span>
        </button>
      </div>
    </form>
  )
}

const Input = ({
  id,
  type = "text",
  label,
  value,
  required,
  disabled,
  onChange,
}) => {
  return (
    <div className="st2-landing-input">
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
      <input
        type={type}
        id={id}
        name={id}
        value={value}
        disabled={disabled}
        required={required}
        onChange={onChange}
      />
    </div>
  )
}

const Checkbox = ({ checked, required, children, more, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="st2-landing-checkbox">
      <label>
        <input
          type="checkbox"
          checked={checked}
          required={required}
          onChange={onChange}
        />
        <span>
          {required && <sup>*</sup>} {children}
          {more && (
            <>
              ...{" "}
              <button
                type="button"
                onClick={() => setIsOpen(prevOpen => !prevOpen)}
              >
                pokaż {isOpen ? "mniej" : "więcej"}
              </button>
            </>
          )}
        </span>
      </label>
      {more && (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="st2-landing-checkbox__dropdown"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: "tween", duration: 0.3 }}
            >
              <p>{more}</p>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  )
}

export default Form
