import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Confirmation = () => {
  return (
    <section className="st2-landing-confirmation">
      <div className="st2-landing-confirmation__container">
        <div className="st2-landing-confirmation__container-inner">
          <div className="st2-landing-confirmation__header">
            <Link
              className="st2-landing-confirmation__logo st2-landing-confirmation__logo--left"
              to="/"
            >
              <img
                src={
                  require("assets/images/sky-trust-ii-landing/logo-ti.svg")
                    .default
                }
                alt="Trust Investment"
              />
            </Link>
            <div className="st2-landing-confirmation__logo st2-landing-confirmation__logo--right">
              <img
                src={
                  require("assets/images/sky-trust-ii-landing/logo-st.svg")
                    .default
                }
                alt="Sky Trust"
              />
            </div>
          </div>
          <div className="st2-landing-confirmation__content">
            <h1>
              <span>Dziękujemy</span> za kontakt
            </h1>
            <h2>Już niedługo poznasz ofertę</h2>
            <div className="st2-landing-confirmation__cta">
              <Link to="/">Strona główna</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Confirmation
