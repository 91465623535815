import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { Content, Form } from "./components"

const Landing = () => {
  return (
    <section className="st2-landing">
      <div className="st2-landing__container">
        <div className="st2-landing__container-inner">
          <div className="st2-landing__header">
            <Link className="st2-landing__logo st2-landing__logo--left" to="/">
              <img
                src={
                  require("assets/images/sky-trust-ii-landing/logo-ti.svg")
                    .default
                }
                alt="Trust Investment"
              />
            </Link>
            <div className="st2-landing__logo st2-landing__logo--right">
              <img
                src={
                  require("assets/images/sky-trust-ii-landing/logo-st.svg")
                    .default
                }
                alt="Sky Trust"
              />
            </div>
          </div>
          <div className="st2-landing__details">
            <Content />
          </div>
          <div className="st2-landing__building">
            <div className="st2-landing__building-caption st2-landing__building-caption--top">
              <p>ETAP II</p>
            </div>
            <div className="st2-landing__building-image">
              <div
                style={{
                  backgroundImage: `url(${
                    require("assets/images/sky-trust-ii-landing/atelier.jpg")
                      .default
                  })`,
                }}
              />
            </div>
            <div className="st2-landing__building-caption st2-landing__building-caption--bottom">
              <p>ATELIER</p>
            </div>
          </div>
          <div className="st2-landing__offer">
            <div>
              <h3>Poznaj ofertę...</h3>
              <Form />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Landing
